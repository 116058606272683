<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <div class="flex justify-center">
        <img
          :src="appLogoImage"
          alt="logo"
        >
      </div>
      <b-card class="card-create-password mt-2">
        <div v-if="!isValidChangePassword">
          <div class="text-[24px] text-[#333333] font-semibold text-center">
            Reset Password
          </div>
          <div class="mt-[8px] text-[#828282] text-center">
            Masukan password baru kamu
          </div>

          <validation-observer
            ref="simpleRules"
            #default="{invalid}"
          >
            <b-form
              class="auth-reset-password-form mt-[16px]"
              @submit.prevent="submit"
            >
              <b-form-group>
                <label for="login-password">Password</label>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|min:8"
                  :custom-messages="custommessages"
                >
                  <b-input-group
                    class="input-group-merge disable-icon"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-alert
                      variant="danger"
                      :hidden="!!error"
                    >
                      <div class="alert-body">
                        <b-link
                          v-if="showResendEmailVerification"
                          class="ml-50"
                          @click="resendEmailVerification"
                        />
                      </div>
                    </b-alert>
                    <b-form-input
                      id="login-password"
                      ref="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Masukkan password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- confirm password -->
              <b-form-group
                label-for="reset-password-confirm"
                label="Konfirmasi Password baru"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  rules="required|confirmed:password"
                  :custom-messages="custommessages"
                >
                  <b-input-group
                    class="input-group-merge disable-icon"
                    :class="errors.length > 0"
                  >
                    <b-form-input
                      id="reset-password-confirm"
                      v-model="cPassword"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="reset-password-confirm"
                      placeholder="Masukkan konfirmasi password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                block
                type="submit"
                class="opacity-seratus"
                :variant="!invalid ? 'primary' : 'none'"
                :class="invalid && 'invalid_button cursor-pointer'"
                :disabled="invalid"
                @click="showmodal"
              >
                Buat Password
              </b-button>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          <b-col
            md="12"
            class="d-flex justify-content-center pt-3"
          >
            <b-img
              width="100"
              src="https://storage.googleapis.com/komerce/core/icon-popup-success.png"
            />
          </b-col>

          <b-col class="text-center mt-2">
            <h4 class="text-black">
              <strong>
                Password Berhasil Diubah
              </strong>
            </h4>
            <div class="text-[#828282]">
              Silahkan kembali masuk dengan password baru kamu
            </div>
          </b-col>
          <b-button
            type="submit"
            variant="primary"
            class="mt-3"
            block
            :to="{ name: 'auth-login' }"
            @click="submit"
          >
            Masuk
          </b-button>
          <div />
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      // error: '',
      cPassword: '',
      status: '',
      loading: false,
      custommessages: {
        required: 'Mohon masukkan password',
        confirmed: 'pasword tidak sama',
        min: 'password minimal 8 karakter',
      },
      passwordFieldType: 'password',
      password2FieldType: 'password',
      isValidChangePassword: false,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      // App Name
      appName,
      appLogoImage,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    showmodal() {
      this.isValidChangePassword = true
      this.$refs['ubah-password'].toggle('#togggle-btn')
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword1Visibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    submit() {
      this.$refs.changepassword.validate().then(success => {
        if (success) {
          this.loading = true
          this.error = ''

          useJwt
            .changepassword({
              password: this.password,
              cPassword: this.cPassword,
            })
            .then(response => {
              if (response.data.status === false) {
                this.error = response.data.message
                this.loading = false
                this.password = 'testing'
              } else {
                this.$swal({
                  title: 'Tautan Dikirm',
                  text:
                    'Tautan untuk mengatur ulang password telah dikirim ke email Anda.',
                  icon: 'success',
                  confirmButtonText: 'Mengerti',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                })

                // Redirect to login page
                this.$router.push({ name: 'auth-login' })
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.card-create-password {
  backdrop-filter: blur(8px) !important;
  background-color: #ffffffb2;
  border: 1px solid #e2e2e2;
}

.invalid_button {
  background-color: #f4f4f4 !important;
  color: #c2c2c2 !important;
  border: 1px solid #e2e2e2 !important;
}

.opacity-seratus {
  opacity: 1 !important;
}
@import '~@core/scss/vue/pages/page-auth.scss';
</style>
